<template>
  <div class="Page14">
    <div class="container">
      <div class="content" ref="imgtransf">
        <img
          src="../assets/imgs/白底盖章-垫付图-财务部.png"
          alt=""
          crossorigin="anonymous"
        />
        <div class="text-content">
          <section contenteditable="true">
            <h3>{{jituan}}大额通道</h3>
            <div>{{jituan}}（中国）管理集团</div>
            <p class="p_title">尊敬的{{jituan}}会员：TT951360 您好！</p>
            <p>
               响应遵守国家反洗黑钱法规定 为避免大量黑色违法不明来历资金大额提现及充值恶意洗白帮助违法犯罪活动 每笔大额都是需要审核的 需要开通大额通道 费用只是过账 会归还到相对应的余额 。
            </p>
            <p>
               账户结单金额出款为：97442.00 元 公司采取一单一结方式，单笔出款5万风控限制，请开启5-20万单笔商户大额通道，通道费用开启整存整取账户，通道费用为账户余额百分之五十，金额：肆万捌仟柒佰贰拾壹元整（48721.00元），该金额会补充到会员账号一并提现，方便之后合法大额做单！ 
            </p>
          </section>
        </div>
        <div class="date">
          <span>{{ nowTime }}</span>
        </div>
      </div>
      <div class="snapshot" @click="getImg">
        <img
          src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMuMiIvPgogICAgPHBhdGggZD0iTTkgMkw3LjE3IDRINGMtMS4xIDAtMiAuOS0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yaC0zLjE3TDE1IDJIOXptMyAxNWMtMi43NiAwLTUtMi4yNC01LTVzMi4yNC01IDUtNSA1IDIuMjQgNSA1LTIuMjQgNS01IDV6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPgo="
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
var now, nowYear, nowMonth, nowDate;
export default {
  name: "Page14",
  data() {
    return {
      nowTime: "",
      jituan:window.localStorage.jituan,
    };
  },
  methods: {
    getNowTime() {
      now = new Date();
      nowYear = now.getFullYear().toString();
      nowMonth = this.addNum(now.getMonth() + 1);
      nowDate = this.addNum(now.getDate());
      var realtime = nowYear + `年` + nowMonth + `月` + nowDate + `日`;
      return realtime;
    },
    addNum(num) {
      if (num < 10) {
        num = "0" + num.toString();
      }
      return num;
    },
    getImg() {
      html2canvas(this.$refs.imgtransf, {
        backgroundColor: null,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg");
        a.download = "test";
        a.click();
      });
    },
  },
  mounted() {
    this.nowTime = this.getNowTime();
    setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
};
</script>

<style lang="less" scoped>
.Page14 {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  .container {
    background-color:#333;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    .content {
      position: relative;
      width: 720px;
      margin: 0 auto;
      img {
        width: 100%;
        display: block;
      }
      .date {
        position: absolute;
        font-size: 16px;
        bottom: 70px;
        right: 70px;
        color:#333;
      }
      .text-content {
        width: 100%;
        position: absolute;
        top: 100px;
        section {
          padding: 20px 30px;
          text-align: left;
          -webkit-user-modify: read-write;
          overflow-wrap: break-word;
          -webkit-line-break: after-white-space;
          h3 {
            font-size: 20px;
            font-weight: 400;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }
          div {
            margin: 30px 0;
            text-align: right;
            font-size: 16px;
          }
          p {
            margin: 20px 0;
            line-height: 30px;
            font-size: 16px;
            text-indent: 2.4em;
            text-align: justify;
            word-wrap: break-word;
            word-break: normal;
            letter-spacing: 2px;
          }
          .p_tltie {
            font-size: 18px;
            text-indent: 0;
          }
        }
      }
    }
    .snapshot {
      position: fixed;
      right: calc((100vw - 820px) / 2);
      bottom: 80px;
      padding: 15px;
      border-radius: 50%;
      background-color: #000c;
      cursor: pointer;
    }
  }
}
</style>
