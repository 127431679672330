<template>
  <div class="Page10">
    <div class="container">
      <div class="content" ref="imgtransf">
        <img
          src="../assets/imgs/24-10客户开通大额通道.png"
          alt=""
          crossorigin="anonymous"
        />
        <div class="date">
          <span>{{ nowTime }}</span>
        </div>
        <div class="text-content">
          <section contenteditable="true">
            <div class="top_text" contenteditable="false">系统提示</div>
            <div class="center_text">
              <p>尊敬的{{company}}用户：DQ199305，您好！</p>
              <p>
                  账户出款金额为：144427.00 元 公司采取一单一结方式，单笔出款10万元风控限制，请开启10-50万元单笔商户大额通道开启整存整取账户，通道费用为账户余额30%，金额:（13328.10元）壹万叁仟叁佰贰拾捌元壹角零分，该金额会补充到会员账号一并提现，方便之后大额做单！  
              </p>
            </div>
            <div class="footer_text" contenteditable="false">
              <span>确认</span>
            </div>
          </section>
        </div>
      </div>
      <div class="snapshot" @click="getImg">
        <img
          src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMuMiIvPgogICAgPHBhdGggZD0iTTkgMkw3LjE3IDRINGMtMS4xIDAtMiAuOS0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yaC0zLjE3TDE1IDJIOXptMyAxNWMtMi43NiAwLTUtMi4yNC01LTVzMi4yNC01IDUtNSA1IDIuMjQgNSA1LTIuMjQgNS01IDV6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPgo="
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
var now, nowYear, nowMonth, nowDate, nowHour, nowMinutes, nowSeconds;
export default {
  name: "Page10",
  data() {
    return {
      nowTime: "",
      company:window.localStorage.company,
    };
  },
  methods: {
    getNowTime() {
      now = new Date();
      nowYear = now.getFullYear().toString();
      nowMonth = this.addNum(now.getMonth() + 1);
      nowDate = this.addNum(now.getDate());
      nowHour = this.addNum(now.getHours());
      nowMinutes = this.addNum(now.getMinutes());
      nowSeconds = this.addNum(now.getSeconds());
      var realtime =
        `当前时间：` +
        nowYear +
        `-` +
        nowMonth +
        `-` +
        nowDate +
        ` ` +
        nowHour +
        `:` +
        nowMinutes +
        `:` +
        nowSeconds;
      return realtime;
    },
    addNum(num) {
      if (num < 10) {
        num = "0" + num.toString();
      }
      return num;
    },
    getImg() {
      html2canvas(this.$refs.imgtransf, {
        backgroundColor: null,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg");
        a.download = "test";
        a.click();
      });
    },
  },
  mounted() {
    this.nowTime = this.getNowTime();
    setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
};
</script>

<style lang="less" scoped>
.Page10 {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  .container {
    background-color:#333;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    .content {
      position: relative;
      width: 1440px;
      margin: 40px auto 0;
      text-align: center;
      img {
        width: 100%;
        display: block;
      }
      .date {
        position: absolute;
        top: 36px;
        left: 420px;
        font-size: 16px;
        color: #f000c7;
      }
      .text-content {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: #000000b3;
        section {
          width: 600px;
          position: fixed;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 10px 20px;
          font-size: 16px;
          line-height: 24px;
          color:#333;
          background-color: #fff;
          text-align: left;
          color: #f000c7;
          -webkit-user-modify: read-write;
          overflow-wrap: break-word;
          -webkit-line-break: after-white-space;
          .top_text {
            padding: 10px 0;
          }
          .footer_text {
            margin-top: 10px;
            padding: 10px 0;
            text-align: center;
            span {
              padding: 10px 20px;
              border-radius: 8px;
              color: #fff;
              background-color: #f000c7;
            }
          }
        }
      }
    }
    .snapshot {
      position: fixed;
      right: calc((100vw - 1440px) / 2);
      bottom: 80px;
      padding: 15px;
      border-radius: 50%;
      background-color: #000c;
      cursor: pointer;
    }
  }
}
</style>
