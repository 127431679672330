import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Page1 from "../components/Page1.vue"
import Page2 from "../components/Page2.vue"
import Page3 from "../components/Page3.vue"
import Page4 from "../components/Page4.vue"
import Page5 from "../components/Page5.vue"
import Page6 from "../components/Page6.vue"
import Page7 from "../components/Page7.vue"
import Page8 from "../components/Page8.vue"
import Page9 from "../components/Page9.vue"
import Page10 from "../components/Page10.vue"
import Page11 from "../components/Page11.vue"
import Page12 from "../components/Page12.vue"
import Page13 from "../components/Page13.vue"
import Page14 from "../components/Page14.vue"
import Page15 from "../components/Page15.vue"
import Page16 from "../components/Page16.vue"
import Page21 from "../components/Page21.vue"
import Page22 from "../components/Page22.vue"
import Page23 from "../components/Page23.vue"
import Page24 from "../components/Page24.vue"
import Page25 from "../components/Page25.vue"
import Page26 from "../components/Page26.vue"
import Page27 from "../components/Page27.vue"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/Page1',
    name: 'Page1',
    component: Page1
  },
  {
    path: '/Page2',
    name: 'Page2',
    component: Page2
  },
  {
    path: '/Page3',
    name: 'Page3',
    component: Page3
  },
  {
    path: '/Page4',
    name: 'Page4',
    component: Page4
  },
  {
    path: '/Page5',
    name: 'Page5',
    component: Page5
  },
  {
    path: '/Page6',
    name: 'Page6',
    component: Page6
  },
  {
    path: '/Page7',
    name: 'Page7',
    component: Page7
  },
  {
    path: '/Page8',
    name: 'Page8',
    component: Page8
  },
  {
    path: '/Page9',
    name: 'Page9',
    component: Page9
  },
  {
    path: '/Page10',
    name: 'Page10',
    component: Page10
  },
  {
    path: '/Page11',
    name: 'Page11',
    component: Page11
  },
  {
    path: '/Page12',
    name: 'Page12',
    component: Page12
  },
  {
    path: '/Page13',
    name: 'Page13',
    component: Page13
  },
  {
    path: '/Page14',
    name: 'Page14',
    component: Page14
  },
  {
    path: '/Page15',
    name: 'Page15',
    component: Page15
  },
  {
    path: '/Page16',
    name: 'Page16',
    component: Page16
  },
  {
    path: '/Page21',
    name: 'Page21',
    component: Page21
  },
  {
    path: '/Page22',
    name: 'Page22',
    component: Page22
  },
  {
    path: '/Page23',
    name: 'Page23',
    component: Page23
  },
  {
    path: '/Page24',
    name: 'Page24',
    component: Page24
  },
  {
    path: '/Page25',
    name: 'Page25',
    component: Page25
  },
  {
    path:'/Page26',
    name:'Page26',
    component:Page26
  },
  {
    path:'/Page27',
    name:'Page27',
    component:Page27
  },
  // {
  //   path:'/Page28',
  //   name:'Page28',
  //   component:Page28
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
