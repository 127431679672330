<template>
  <div class="Page22">
    <div class="container">
      <div class="content" ref="imgtransf">
        <img
          src="../assets/imgs/白底盖章-律师涵.png"
          alt=""
          crossorigin="anonymous"
        />
        <div class="text-content">
          <section contenteditable="true">
            <h2>北 京 中 闻 律 师 事 务 所</h2>
            <h6>2021中闻律函[ 2931 ]号</h6>
            <h3>律 师 函</h3>
            <div>xx先生：</div>
            <p>
              北京中闻律师事务所接受{{jituan}}（中国）管理集团（以下称委托人）的委托，依法指派我们作为其代理人，处理您拖欠委托人xx款项纠纷事宜。现就您拖欠委托人款项的相关事实和法律问题，正式函告于您：
            </p>
            <p>我们收到并审查了{{jituan}}（中国）管理集团提交的下列材料：</p>
            <p>1、您书写的垫资协议xx元的欠条一张;</p>
            <p>2、欠条形成的原因的证明; 。</p>
            <p>
              依据{{jituan}}（中国）管理集团的陈述及我们对上述材料的审查，我们初步认定如下事实：
            </p>
            <p>1、您于xx年xx月xx日书写的欠款人民币xx元整的欠款协议一张。</p>
            <p>
              2、截止目前为止，您并没有按照“协议”向委托人偿还拖欠的款项xx元整。
            </p>
            <p>根据前述认定的事实，我们提出如下法律意见：</p>
            <p>
              1、{{jituan}}（中国）管理集团与您之间的债权债务法律关系明确，您应及时向{{jituan}}（中国）管理集团履行所欠债务。
            </p>
            <p>
              2、xx先生逾期偿还欠款的行为已经违反相关法律法规的规定，依法应当承担违约责任。xx先生逾期未向{{jituan}}（中国）管理集团履行债务的行为，已经违反我国《民法通则》第一百零六条和《合同法》第一百零七条的规定，xx先生依法应当承担继续履行支付欠款人民币xx元整的义务。
            </p>
            <p>
              基于上述事实，为妥善解决纠纷，维护委托人合法权益，同时也避免给xx先生的声誉带来负面影响，请xx先生自收到本函之日起xx日内，将欠款xx元，逾期付款的利息损失xx元支付给{{jituan}}（中国）管理集团。具体付款事宜可与{{jituan}}（中国）管理集团联系。
            </p>
            <p>
              如xx先生不履行上述义务，本所将根据委托人的授权通过一切必要的法律手段主张权利，维护委托人的合法权益。
            </p>
            <p>特此函告</p>
          </section>
        </div>
        <div class="date">
          <span>{{ nowTime }}</span>
        </div>
      </div>
      <div class="snapshot" @click="getImg">
        <img
          src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMuMiIvPgogICAgPHBhdGggZD0iTTkgMkw3LjE3IDRINGMtMS4xIDAtMiAuOS0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yaC0zLjE3TDE1IDJIOXptMyAxNWMtMi43NiAwLTUtMi4yNC01LTVzMi4yNC01IDUtNSA1IDIuMjQgNSA1LTIuMjQgNS01IDV6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPgo="
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
var now, nowYear, nowMonth, nowDate;
export default {
  name: "Page25",
  data() {
    return {
      nowTime: "",
      jituan:window.localStorage.jituan,
    };
  },
  methods: {
    getNowTime() {
      now = new Date();
      nowYear = now.getFullYear().toString();
      nowMonth = this.addNum(now.getMonth() + 1);
      nowDate = this.addNum(now.getDate());
      var realtime = nowYear + `年` + nowMonth + `月` + nowDate + `日`;
      return realtime;
    },
    addNum(num) {
      if (num < 10) {
        num = "0" + num.toString();
      }
      return num;
    },
    getImg() {
      html2canvas(this.$refs.imgtransf, {
        backgroundColor: null,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg");
        a.download = "test";
        a.click();
      });
    },
  },
  mounted() {
    this.nowTime = this.getNowTime();
    setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
};
</script>

<style lang="less" scoped>
.Page22 {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  .container {
    background-color:#333;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    .content {
      position: relative;
      width: 720px;
      margin: 0 auto;
      img {
        width: 100%;
        display: block;
      }
      .date {
        position: absolute;
        font-size: 16px;
        bottom: 70px;
        right: 90px;
        color:#333;
      }
      .text-content {
        width: 100%;
        position: absolute;
        top: 100px;
        section {
          padding: 20px 30px;
          -webkit-user-modify: read-write;
          overflow-wrap: break-word;
          -webkit-line-break: after-white-space;
          h3 {
            font-size: 20px;
            font-weight: 400;
            margin: 15px 0;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }
          h2 {
            font-size: 24px;
            font-weight: 400;
            display: block;
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }
          div {
            margin: 30px 0;
            text-align: left;
            font-size: 16px;
          }
          p {
            line-height: 28px;
            font-size: 14px;
            text-indent: 2.4em;
            text-align: justify;
            word-wrap: break-word;
            word-break: normal;
          }
          h6 {
            margin-top: 6px;
          }
        }
      }
    }
    .snapshot {
      position: fixed;
      right: calc((100vw - 820px) / 2);
      bottom: 80px;
      padding: 15px;
      border-radius: 50%;
      background-color: #000c;
      cursor: pointer;
    }
  }
}
</style>
