<template>
  <div class="Page5">
    <div class="container">
      <div class="content" ref="imgtransf">
        <img
        class="bg"
          src="../assets/imgs/24-4 妹子信息预约图.png"
          alt=""
          crossorigin="anonymous"
        />
        <div class="text_content">
          <div class="date">
            <span>{{ nowTime }}</span>
          </div>
          <section contenteditable="true">
            <div class="b_input">
              <ul>
                <li>
                  <span contenteditable="false">客户ID</span>
                  <input type="text" placeholder="AC205198" />
                </li>
                <li>
                  <span contenteditable="false">联系电话</span>
                  <input type="text" placeholder="-" />
                </li>
                <li>
                  <span contenteditable="false">客户年龄</span>
                  <input type="text" placeholder="26" />
                </li>
                <li>
                  <span contenteditable="false">预约时间/地区</span>
                  <input type="text" placeholder="上海浦东新区北蔡镇" />
                </li>
                <li>
                  <span contenteditable="false">预约单号</span>
                  <input type="text" placeholder="YT212021050910000" />
                </li>
              </ul>
              <ul class="ul2">
                <li>
                  <span contenteditable="false">服务责任人</span>
                  <input type="text" placeholder="小小" />
                </li>
                <li>
                  <span contenteditable="false">服务评价</span>
                  <input type="text" placeholder="★★★★★" />
                </li>
                <li>
                  <span contenteditable="false">预约对象</span>
                  <input type="text" placeholder="夏冰" />
                </li>
                <li>
                  <span contenteditable="false">数据对接详情</span>
                  <input type="text" placeholder="38" />
                </li>
                <li>
                  <span contenteditable="false">首单金额/备注</span>
                  <input type="text" placeholder="预约成功" />
                </li>
              </ul>
            </div>
            <div class="photo">
              <div>
                <div class="p_le">
                  <div id="p_lem">
                    <button>选择文件</button>
                    <img id="showleft" src="" alt="" />
                  </div>
                  <div class="p_left">
                    <input
                      type="file"
                      id="pleft"
                      name="pic"
                      @change="changepic($event, 1)"
                    />
                  </div>
                </div>
                <div class="p_re">
                  <div id="p_lem">
                    <button>选择文件</button>
                    <img id="showright" src="" alt="" />
                  </div>
                  <div class="p_left">
                    <input
                      type="file"
                      id="pleft"
                      name="pic"
                      @change="changepic($event, 2)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="snapshot" @click="getImg">
        <img
          src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMuMiIvPgogICAgPHBhdGggZD0iTTkgMkw3LjE3IDRINGMtMS4xIDAtMiAuOS0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yaC0zLjE3TDE1IDJIOXptMyAxNWMtMi43NiAwLTUtMi4yNC01LTVzMi4yNC01IDUtNSA1IDIuMjQgNSA1LTIuMjQgNS01IDV6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPgo="
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
var now, nowYear, nowMonth, nowDate, nowHour, nowMinutes, nowSeconds;
export default {
  name: "Page5",
  data() {
    return {
      nowTime: "",
      company: window.localStorage.company,
    };
  },
  methods: {
    getNowTime() {
      now = new Date();
      nowYear = now.getFullYear().toString();
      nowMonth = this.addNum(now.getMonth() + 1);
      nowDate = this.addNum(now.getDate());
      nowHour = this.addNum(now.getHours());
      nowMinutes = this.addNum(now.getMinutes());
      nowSeconds = this.addNum(now.getSeconds());
      var realtime =
        nowYear +
        `-` +
        nowMonth +
        `-` +
        nowDate +
        ` ` +
        nowHour +
        `:` +
        nowMinutes +
        `:` +
        nowSeconds;
      return realtime;
    },
    addNum(num) {
      if (num < 10) {
        num = "0" + num.toString();
      }
      return num;
    },
    getImg() {
      html2canvas(this.$refs.imgtransf, {
        backgroundColor: null,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg");
        a.download = "test";
        a.click();
      });
    },
    changepic(event, num) {
      let reads = new FileReader();
      let file = event.target.files;
      reads.readAsDataURL(file[0]);
      if (num == 1) {
        reads.onload = function (event) {
          console.log(event);
          document.getElementById("showleft").src = event.currentTarget.result;
        };
      } else {
        reads.onload = function (event) {
          console.log(event);
          document.getElementById("showright").src = event.currentTarget.result;
        };
      }
    },
  },
  mounted() {
    this.nowTime = this.getNowTime();
    setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
button,
input,
textarea {
  color: inherit;
  font: inherit;
}
input {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  margin: 0em;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}
.Page5 {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  .container {
    height: 100vh;
    overflow-y: scroll;
    background-color:#333;
    .content {
      position: relative;
      width: 720px;
      margin: 0 auto;
      .bg {
        width: 100%;
        display: block;
      }
      .text_content {
        width: 100%;
        position: absolute;
        top: 170px;
        .date {
          position: absolute;
          color: #fff;
          font-size: 16px;
          left: 36px;
        }
        section {
          margin-top: 164px;
          font-size: 15px;
          color:#333;
          -webkit-user-modify: read-write;
          overflow-wrap: break-word;
          -webkit-line-break: after-white-space;
          .b_input {
            width: 640px;
            margin: 0 auto;
            ul {
              display: inline-block;
              list-style: none;
              li {
                margin-bottom: 28px;
                text-align: left;
                display: list-item;
                span {
                  display: inline-block;
                  width: 120px;
                  color: #f000c7;
                  margin-right: 24px;
                }
                input {
                  width: 150px;
                  border: none;
                  outline: none;
                  background: transparent;
                  color:#333;
                  padding: 0;
                  letter-spacing: 0;
                }
              }
            }
            .ul2 {
              margin-left: 20px;
            }
          }
          .photo {
            margin-top: 110px;
            div {
              .p_le {
                display: inline-block;
                width: 310px;
                height: 170px;
                border: 1px solid #6f7786;
                position: relative;
                #p_lem {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  button {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                  img {
                    position: relative;
                    height: 100%;
                    object-fit: contain;
                    display: block;
                    left: 50%;
                    transform: translate(-50%);
                  }
                }
                .p_left {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  z-index: 2;
                  input {
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                  }
                }
              }
              .p_re {
                display: inline-block;
                width: 310px;
                height: 170px;
                border: 1px solid #6f7786;
                position: relative;
                #p_lem {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  button {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                  img {
                    position: relative;
                    height: 100%;
                    object-fit: contain;
                    display: block;
                    left: 50%;
                    transform: translate(-50%);
                  }
                }
                .p_left {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  z-index: 2;
                  input {
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .snapshot {
      position: fixed;
      right: calc((100vw - 820px) / 2);
      bottom: 80px;
      padding: 15px;
      border-radius: 50%;
      background-color: #000c;
      cursor: pointer;
    }
  }
}
</style>
