<template>
  <div class="Page4">
    <div class="container">
      <div class="content" ref="imgtransf">
        <img
          src="../assets/imgs/24-3客户最后一次违规操作-1.png"
          alt=""
          crossorigin="anonymous"
        />
        <div class="text_content">
          <div class="date">
            <span>{{ nowTime }}</span>
          </div>
          <section contenteditable="true">
            <div class="top_content">
              <p>
                尊敬的{{company}}会员:
                <span>1254214</span>
                您好！
              </p>
            </div>
            <div class="center_content">
              <p>
                由于会员没有按照数据内容点击系统出现
                <span>错单/漏单</span>
              </p>
              <p>导致数据错误无法录入程序系统从而出现掉单情况</p>
              <p>为了尽快的能让会员挽回损失</p>
              <p>
                <span
                  >公司再次给予会员一次补单名额，望会员减少错误</span
                >
              </p>
              <p>为了秉承公司合作共赢的发展宗旨，所有补单名额均享有包赔协议</p>
              <p>需会员重新对接数据完成数据点击恢复数据正常提现出款</p>
              <p>
                <span
                  >（注：未完成的数据保留60分钟，超时未完成，数据清空）</span
                >
              </p>
            </div>
            <div class="bottom_content">
              <p>
                <span
                  >方案一：现需补足账号余额3倍资金重新点击对接提现</span
                >
              </p>
                            <p>
                <span
                  >方案二：直接补入账户80000元，无需点击，即可提现</span
                >
              </p>
            </div>
          </section>
        </div>
      </div>
      <div class="snapshot" @click="getImg">
        <img
          src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMuMiIvPgogICAgPHBhdGggZD0iTTkgMkw3LjE3IDRINGMtMS4xIDAtMiAuOS0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yaC0zLjE3TDE1IDJIOXptMyAxNWMtMi43NiAwLTUtMi4yNC01LTVzMi4yNC01IDUtNSA1IDIuMjQgNSA1LTIuMjQgNS01IDV6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPgo="
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
var now, nowYear, nowMonth, nowDate, nowHour, nowMinutes, nowSeconds;
export default {
  name: "Page4",
  data() {
    return {
      nowTime: "",
      company:window.localStorage.company,
    };
  },
  methods: {
    getNowTime() {
      now = new Date();
      nowYear = now.getFullYear().toString();
      nowMonth = this.addNum(now.getMonth() + 1);
      nowDate = this.addNum(now.getDate());
      nowHour = this.addNum(now.getHours());
      nowMinutes = this.addNum(now.getMinutes());
      nowSeconds = this.addNum(now.getSeconds());
      var realtime =
        nowYear +
        `-` +
        nowMonth +
        `-` +
        nowDate +
        ` ` +
        nowHour +
        `:` +
        nowMinutes +
        `:` +
        nowSeconds;
      return realtime;
    },
    addNum(num) {
      if (num < 10) {
        num = "0" + num.toString();
      }
      return num;
    },
    getImg() {
      html2canvas(this.$refs.imgtransf, {
        backgroundColor: null,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg");
        a.download = "test";
        a.click();
      });
    },
  },
  mounted() {
    this.nowTime = this.getNowTime();
    setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
button, input, textarea {
    color: inherit;
    font: inherit;
}
.Page4 {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  .container {
    height: 100vh;
    overflow-y: scroll;
    background-color:#333;
    .content {
      position: relative;
      width: 720px;
      margin: 0 auto;
      img {
        width: 100%;
        display: block;
      }
      .text_content {
        width: 100%;
        position: absolute;
        top: 500px;
        .date {
          position: absolute;
          color: #fff;
          font-size: 16px;
          right: 92px;
        }
        section {
          -webkit-user-modify: read-write;
          overflow-wrap: break-word;
          -webkit-line-break: after-white-space;
          margin-top: 40px;
          font-size: 20px;
          color:#333;
          .top_content {
            p {
              span {
                color: #f000c7;
              }
            }
          }
          .center_content {
            // margin-top: 30px;
            p {
              margin-top: 20px;
              span {
                color: #f000c7;
              }
            }
          }
          .bottom_content {
            margin-top: 80px;
            p {
              span {
                color: #f000c7;
              }
            }
          }
        }
      }
    }
    .snapshot {
      position: fixed;
      right: calc((100vw - 820px) / 2);
      bottom: 80px;
      padding: 15px;
      border-radius: 50%;
      background-color: #000c;
      cursor: pointer;
    }
  }
}
</style>
