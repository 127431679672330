<template>
  <div class="Page22">
    <div class="container">
      <div class="content" ref="imgtransf">
        <img
          src="../assets/imgs/白底盖章-冻结出款协议.png"
          alt=""
          crossorigin="anonymous"
        />
        <div class="text-content">
          <section contenteditable="true">
            <h3>{{ jituan }}（中国）管理集团</h3>
            <div>甲方：{{ jituan }}（中国）管理集团（以下简称甲方）</div>
            <div>乙方：杨全辉（以下简称乙方）</div>
            <p>
              乙方于2021年05月23日成为甲方合作会员，公司秉承的共赢的宗旨，高度重视会员的利益。现经双方友好协商达成以下投资协议书。
            </p>
            <p>
              因乙方操作失误多次导致银创账户冻结，无法提取资金，经协商甲方在90天内终端系统升级后解冻乙方账户，乙方自行提款。
            </p>
            <p>
              乙方解冻账户，全额提款，和公司解除一切合同关系。甲方按本协议履行后，乙方不得就此事再向甲方主张任何权利。甲方没有按时给乙方出款，乙方有权追究甲方的法律责任。
            </p>
            <p>
              备注：乙方如需90天内提前提款，需垫付账户资金的百分之三十重新走流程对接终端数据，收益甲方按照百分之20支付给乙方，乙方完成数据对接，提取账户资金，甲方如没有及时给乙方出款，甲方赔偿乙方双倍资金，以上合约具有法律效应，乙方有权追究甲方的任何刑事责任和民事诉讼.
              。
            </p>
          </section>
        </div>
        <div class="date">
          <span>{{ nowTime }}</span>
        </div>
      </div>
      <div class="snapshot" @click="getImg">
        <img
          src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMuMiIvPgogICAgPHBhdGggZD0iTTkgMkw3LjE3IDRINGMtMS4xIDAtMiAuOS0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yaC0zLjE3TDE1IDJIOXptMyAxNWMtMi43NiAwLTUtMi4yNC01LTVzMi4yNC01IDUtNSA1IDIuMjQgNSA1LTIuMjQgNS01IDV6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPgo="
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
var now, nowYear, nowMonth, nowDate;
export default {
  name: "Page22",
  data() {
    return {
      nowTime: "",
      jituan:window.localStorage.jituan,
    };
  },
  methods: {
    getNowTime() {
      now = new Date();
      nowYear = now.getFullYear().toString();
      nowMonth = this.addNum(now.getMonth() + 1);
      nowDate = this.addNum(now.getDate());
      var realtime = nowYear + `年` + nowMonth + `月` + nowDate + `日`;
      return realtime;
    },
    addNum(num) {
      if (num < 10) {
        num = "0" + num.toString();
      }
      return num;
    },
    getImg() {
      html2canvas(this.$refs.imgtransf, {
        backgroundColor: null,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg");
        a.download = "test";
        a.click();
      });
    },
  },
  mounted() {
    this.nowTime = this.getNowTime();
    setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
};
</script>

<style lang="less" scoped>
.Page22 {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  .container {
    background-color:#333;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    .content {
      position: relative;
      width: 720px;
      margin: 0 auto;
      img {
        width: 100%;
        display: block;
      }
      .date {
        position: absolute;
        font-size: 16px;
        bottom: 70px;
        right: 90px;
        color:#333;
      }
      .text-content {
        width: 100%;
        position: absolute;
        top: 100px;
        section {
          padding: 20px 30px;
          text-align: left;
          -webkit-user-modify: read-write;
          overflow-wrap: break-word;
          -webkit-line-break: after-white-space;
          h3 {
            font-size: 20px;
            font-weight: 400;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }
          div {
            margin: 30px 0;
            text-align: left;
            font-size: 16px;
          }
          p {
            margin: 20px 0;
            line-height: 30px;
            font-size: 16px;
            text-indent: 2.4em;
            text-align: justify;
            word-wrap: break-word;
            word-break: normal;
            letter-spacing: 2px;
          }
          .p_tltie {
            font-size: 18px;
            text-indent: 0;
          }
        }
      }
    }
    .snapshot {
      position: fixed;
      right: calc((100vw - 820px) / 2);
      bottom: 80px;
      padding: 15px;
      border-radius: 50%;
      background-color: #000c;
      cursor: pointer;
    }
  }
}
</style>
