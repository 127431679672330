<template>
  <div class="Page26">
    <div class="container">
      <div class="content" ref="imgtransf">
        <img src="../assets/imgs/对冲图.png" alt="" crossorigin="anonymous" />
        <div class="text-content">
          <section contenteditable="true">
            <h2 align="left">转账冲正，对方未收到440662.00元的款项。</h2>
            <h3 align="left">转账详情</h3>
            <p align="left">付款账户：6217002750008051007</p>
            <p align="left">收款账户：62179210625605555</p>
            <p align="left">付款金额：<span>440662.00元</span></p>
            <p align="left">付款说明：会员aaaoooo提现！</p>
            <p align="left">付款时间：2021.11.9 15：19：00</p>
            <p align="left" class="buff">状态：数额较大，转账已被冲正！</p>
          </section>
        </div>
      </div>
      <div class="snapshot" @click="getImg">
        <img
          src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMuMiIvPgogICAgPHBhdGggZD0iTTkgMkw3LjE3IDRINGMtMS4xIDAtMiAuOS0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yaC0zLjE3TDE1IDJIOXptMyAxNWMtMi43NiAwLTUtMi4yNC01LTVzMi4yNC01IDUtNSA1IDIuMjQgNSA1LTIuMjQgNS01IDV6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPgo="
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
export default {
  name: "Page26",
  data() {
    return {
      nowTime: "",
      jituan: window.localStorage.jituan,
    };
  },
  methods: {
    getImg() {
      html2canvas(this.$refs.imgtransf, {
        backgroundColor: null,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg");
        a.download = "test";
        a.click();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.Page26 {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  .container {
    background-color:#333;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    .content {
      position: relative;
      width: 600px;
      margin: 0 auto;
      height: 395px;
      top: 50%;
      transform: translate(0, -50%);
      .text-content {
        width: 100%;
        position: absolute;
        top: 100px;
        section {
          padding: 20px 30px;
          -webkit-user-modify: read-write;
          overflow-wrap: break-word;
          -webkit-line-break: after-white-space;
          h2 {
            position: relative;
            top: -100px;
            left: 60px;
            font-weight: 400;
            white-space: nowrap;
            width: 300px;
          }
          h3 {
            position: relative;
            top: -62px;
            left: 100px;
            font-weight: 550;
            white-space: nowrap;
            width: 200px;
          }
          p {
            position: relative;
            left: 120px;
            margin-bottom: 11px;
            top: -30px;
            font-weight: 200;
            width: 330px;
            white-space: nowrap;
            span {
              color: orange;
            }
          }
          .buff {
            left: 150px;
          }
        }
      }
    }
    .snapshot {
      position: fixed;
      right: calc((100vw - 820px) / 2);
      padding: 15px;
      border-radius: 50%;
      background-color: #000c;
      cursor: pointer;
    }
  }
}
</style>
