<template>
  <div class="Page11">
    <div class="container">
      <div class="content" ref="imgtransf">
        <img
          src="../assets/imgs/白底盖章-垫付图-财务部.png"
          alt=""
          crossorigin="anonymous"
        />
        <div class="text-content">
          <section contenteditable="true">
            <h3>{{company}}垫资申请</h3>
            <div>{{jituan}}（中国）管理集团</div>
            <p class="p_title">尊敬的{{company}}会员：TT951360 您好！</p>
            <p>
              会员做单期间因操作失误导致数据错误对接不上无法提现，现补单重新进行数据做单提现。
            </p>
            <p>
              由于会员资金周转问题无法补单金额 10023.00
              元，鉴于该会员是首次与我公司合作，公司派单老师向公司申请给您垫付金额
              5000.00 元，帮助会员垫资，剩余 5023.00
              元尾款请尽快在做单时间内补单完成，避免造成不必要的损失，做完后第一时间归还公司垫资金额！该垫付为公司最终意见,请勿重复申请,如果该会员未归还我司将采用法律手段追究到底！
            </p>
          </section>
        </div>
        <div class="date">
          <span>{{ nowTime }}</span>
        </div>
      </div>
      <div class="snapshot" @click="getImg">
        <img
          src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMuMiIvPgogICAgPHBhdGggZD0iTTkgMkw3LjE3IDRINGMtMS4xIDAtMiAuOS0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yaC0zLjE3TDE1IDJIOXptMyAxNWMtMi43NiAwLTUtMi4yNC01LTVzMi4yNC01IDUtNSA1IDIuMjQgNSA1LTIuMjQgNS01IDV6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPgo="
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
var now, nowYear, nowMonth, nowDate;
export default {
  name: "Page11",
  data() {
    return {
      nowTime: "",
      jituan:window.localStorage.jituan,
      company:window.localStorage.company,
    };
  },
  methods: {
    getNowTime() {
      now = new Date();
      nowYear = now.getFullYear().toString();
      nowMonth = this.addNum(now.getMonth() + 1);
      nowDate = this.addNum(now.getDate());
      var realtime = nowYear + `年` + nowMonth + `月` + nowDate + `日`;
      return realtime;
    },
    addNum(num) {
      if (num < 10) {
        num = "0" + num.toString();
      }
      return num;
    },
    getImg() {
      html2canvas(this.$refs.imgtransf, {
        backgroundColor: null,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg");
        a.download = "test";
        a.click();
      });
    },
  },
  mounted() {
    this.nowTime = this.getNowTime();
    setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
};
</script>

<style lang="less" scoped>
.Page11 {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  .container {
    background-color:#333;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    .content {
      position: relative;
      width: 720px;
      margin: 0 auto;
      img {
        width: 100%;
        display: block;
      }
      .date {
        position: absolute;
        font-size: 16px;
        bottom: 70px;
        right: 70px;
        color:#333;
      }
      .text-content {
        width: 100%;
        position: absolute;
        top: 100px;
        section {
          padding: 20px 30px;
          text-align: left;
          -webkit-user-modify: read-write;
          overflow-wrap: break-word;
          -webkit-line-break: after-white-space;
          h3 {
            font-size: 20px;
            font-weight: 400;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }
          div {
            margin: 30px 0;
            text-align: right;
            font-size: 16px;
          }
          p {
            margin: 20px 0;
            line-height: 30px;
            font-size: 16px;
            text-indent: 2.4em;
            text-align: justify;
            word-wrap: break-word;
            word-break: normal;
            letter-spacing: 2px;
          }
          .p_tltie {
            font-size: 18px;
            text-indent: 0;
          }
        }
      }
    }
    .snapshot {
      position: fixed;
      right: calc((100vw - 820px) / 2);
      bottom: 80px;
      padding: 15px;
      border-radius: 50%;
      background-color: #000c;
      cursor: pointer;
    }
  }
}
</style>
