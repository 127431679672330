<template>
  <div class="Page1">
    <div class="container">
      <div class="content" ref="imgtransf">
        <img src="../assets/imgs/24-9客户抢单后台弹窗.png" alt="" />
        <div class="date">
          <span>{{ nowTime }}</span>
        </div>
        <div class="text-content">
          <section contenteditable="true">
            <ul>
              <li v-for="(item, index) in lists" :key="index">
                <div class="first">
                  <img src="../assets/imgs/65.png" alt="" />
                  <div class="topTitle">
                    <p>
                      订单名称<span
                        :class="{
                          red: item.color == 0,
                          green: item.color == 1,
                        }"
                        >{{ item.name }}</span
                      >
                    </p>
                    <p>
                      任务口令<span
                        :class="{
                          red: item.color == 0,
                          green: item.color == 1,
                        }"
                        >{{ item.code }}</span
                      >
                    </p>
                  </div>
                </div>
                <p class="sec">
                  垫付金额
                  <span
                    :class="{ red: item.color == 0, green: item.color == 1 }"
                    >{{ item.paymoney }}</span
                  >
                  提现金额
                  <span
                    :class="{ red: item.color == 0, green: item.color == 1 }"
                    >{{ item.gainmoney }}</span
                  >
                </p>
                <p class="third">
                  任务剩余次数<span
                    :class="{ red: item.color == 0, green: item.color == 1 }"
                    >{{ item.leavetime }}</span
                  >
                </p>
                <div class="fourth">
                  <p>是否被领取</p>
                  <p class="btn">未领取</p>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
      <div class="snapshot" @click="getImg">
        <img
          src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMuMiIvPgogICAgPHBhdGggZD0iTTkgMkw3LjE3IDRINGMtMS4xIDAtMiAuOS0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yaC0zLjE3TDE1IDJIOXptMyAxNWMtMi43NiAwLTUtMi4yNC01LTVzMi4yNC01IDUtNSA1IDIuMjQgNSA1LTIuMjQgNS01IDV6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPgo="
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
var now, nowYear, nowMonth, nowDate, nowHour, nowMinutes, nowSeconds;
export default {
  name: "Page1",
  data() {
    return {
      nowTime: "",
      company:window.localStorage.company,
      lists: [
        {
          name: "点击量型任务",
          code: "******",
          paymoney: "1001 元",
          gainmoney: "3000+ 元",
          leavetime: "11 次",
          color: 1,
        },
        {
          name: "点击量型任务",
          code: "******",
          paymoney: "3001 元",
          gainmoney: "8000+ 元",
          leavetime: "11 次",
          color: 1,
        },
        {
          name: "点击量型任务",
          code: "******",
          paymoney: "5001 元",
          gainmoney: "10000+ 元",
          leavetime: "11 次",
          color: 1,
        },
        {
          name: "点击量型任务",
          code: "******",
          paymoney: "10001 元",
          gainmoney: "30000+ 元",
          leavetime: "11 次",
          color: 1,
        },
        {
          name: "点击量型任务",
          code: "******",
          paymoney: "30008 元",
          gainmoney: "80000+ 元",
          leavetime: "11 次",
          color: 0,
        },
        {
          name: "点击量型任务",
          code: "******",
          paymoney: "50008 元",
          gainmoney: "100000+ 元",
          leavetime: "11 次",
          color: 1,
        },
        {
          name: "点击量型任务",
          code: "******",
          paymoney: "120000 元",
          gainmoney: "360000+ 元",
          leavetime: "11 次",
          color: 1,
        },
        {
          name: "点击量型任务",
          code: "******",
          paymoney: "240000 元",
          gainmoney: "480000+ 元",
          leavetime: "11 次",
          color: 0,
        },
      ],
    };
  },
  methods: {
    getNowTime() {
      now = new Date();
      nowYear = now.getFullYear().toString();
      nowMonth = this.addNum(now.getMonth()+1);
      nowDate = this.addNum(now.getDate());
      nowHour = this.addNum(now.getHours());
      nowMinutes = this.addNum(now.getMinutes());
      nowSeconds = this.addNum(now.getSeconds());
      var realtime =
        `当前时间：` +
        nowYear +
        `-` +
        nowMonth +
        `-` +
        nowDate +
        ` ` +
        nowHour +
        `:` +
        nowMinutes +
        `:` +
        nowSeconds;
      return realtime;
    },
    addNum(num) {
      if (num < 10) {
        num = "0" + num.toString();
      }
      return num;
    },
    getImg(){
      html2canvas(this.$refs.imgtransf,{
        backgroundColor:null,
        useCORS:true,
        scale:1,
      }).then(canvas =>{
        let a = document.createElement('a');
        a.href = canvas.toDataURL('image/jpeg');
        a.download = 'test';
        a.click();
      })
    }
  },
  mounted() {
    this.nowTime = this.getNowTime();
    setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
};
</script>

<style lang="less" scoped>
.Page1 {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  .container {
    background-color: #333;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    .content {
      position: relative;
      width: 1440px;
      margin: 40px auto 0;
      text-align: center;
      img {
        width: 100%;
        display: block;
      }
      .date {
        position: absolute;
        top: 36px;
        left: 420px;
        font-size: 16px;
        color: #f000c7;
      }
      .text-content {
        position: absolute;
        top: 200px;
        left: 106px;
        section {
          width: 1320px;
          font-size: 14px;
          -webkit-user-modify: read-write;
          overflow-wrap: break-word;
          -webkit-line-break: after-white-space;
          ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            color: #888;
            li {
              width: 288px;
              background-color: #fff;
              padding: 10px;
              border-radius: 6px;
              margin-right: 20px;
              margin-bottom: 20px;
              text-align: left;
              .first {
                justify-content: flex-start;
                padding-bottom: 10px;
                border-bottom: 1px solid #eee;
                display: flex;
                align-items: center;
                img {
                  display: block;
                  width: 60px;
                }
                .topTitle {
                  margin-left: 10px;
                  p {
                    margin: 8px 0;
                    .green {
                      color: #00df00;
                      margin: 0 6px;
                    }
                    .red {
                      color: red;
                      margin: 0 6px;
                    }
                  }
                }
              }
              .sec {
                margin: 10px 0;
                .green {
                  color: #00df00;
                  margin: 0 6px;
                }
                .red {
                  color: red;
                  margin: 0 6px;
                }
              }
              .third {
                margin: 10px 0;
                .green {
                  color: #00df00;
                  margin: 0 6px;
                }
                .red {
                  color: red;
                  margin: 0 6px;
                }
              }
              .fourth {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .btn {
                  padding: 4px 8px;
                  border-radius: 4px;
                  color: #e09900;
                  background-color: #fcad1b33;
                }
              }
            }
          }
        }
      }
    }
    .snapshot {
      position: fixed;
      right: calc((100vw - 1440px) / 2);
      bottom: 80px;
      padding: 15px;
      border-radius: 50%;
      background-color: #000c;
      cursor: pointer;
    }
  }
}
</style>
